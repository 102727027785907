import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ResumeQuery } from '../../graphql';
import Github from '../images/github.svg';
import LinkedIn from '../images/linkedin.svg';
import Location from '../images/location.svg';
import Mail from '../images/mail.svg';
import PDF from '../images/pdf.svg';
import './resume.css';

const Resume = () => {
  const data: ResumeQuery = useStaticQuery(graphql`
    query Resume {
      dataJson {
        jobs {
          title
          dates
          company
          details
        }
        languages
        skills
      }
      pdf: file(relativePath: { eq: "resume.pdf" }) {
        publicURL
      }
    }
  `);

  const { jobs, skills, languages } = data.dataJson;

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div style={{ paddingTop: 20 }}>
        <header>
          <div className="left">
            <h1 className="me">Daniel Hayes</h1>
            <h2 className="role">Software Engineer</h2>
          </div>
          <div className="right">
            <ul className="info">
              <li>
                <Location url="location.svg" /> Brooklyn, NY
              </li>
              <li>
                <Mail url="mail.svg" />{' '}
                <a href="mailto:hello@danhayes.me">hello@danhayes.me</a>
              </li>
              <li>
                <LinkedIn url="linkedin.svg" />
                <a
                  href="https://www.linkedin.com/in/danieladamhayes/"
                  target="_blank"
                  rel="noreferrer"
                >
                  /danieladamhayes
                </a>
              </li>
              <li>
                <Github url="github.svg" />
                <a
                  href="https://github.com/daniel-hayes/"
                  target="_blank"
                  rel="noreferrer"
                >
                  /daniel-hayes
                </a>
              </li>
            </ul>
          </div>
        </header>
        <section className="left">
          <main>
            <h2 className="bold">Relevant Experience</h2>
            {jobs.map(({ details, title, dates, company }, i) => (
              <div key={i} className="job">
                <h3 className="bold">{company}</h3>
                <h4 className="title">
                  {title}, <span className="italic">{dates}</span>
                </h4>
                <ul className="description">
                  {details.map((detail, n) => (
                    <li key={n}>{detail}</li>
                  ))}
                </ul>
              </div>
            ))}
          </main>
          <aside className="sidebar">
            <div className="sidebar-item">
              <h2 className="bold">Skills</h2>
              <ul className="side-info">
                {skills.map((skill, i) => (
                  <li key={i}>{skill}</li>
                ))}
              </ul>
            </div>
            <div className="sidebar-item">
              <h2 className="bold">Languages</h2>
              <ul className="side-info">
                {languages.map((language, i) => (
                  <li key={i}>{language}</li>
                ))}
              </ul>
            </div>
            <div className="sidebar-item">
              <h2 className="bold">Education</h2>
              <ul className="education side-info">
                <li className="bold">Syracuse University</li>
                <li className="italic">May 2014</li>
                <li>B.A. in Psychology</li>
                <li>Minor in Entrepreneurship</li>
              </ul>
            </div>
          </aside>
        </section>
        <div className="float">
          <a
            title="Download PDF version of resume"
            className="pdf-wrapper"
            href={data.pdf.publicURL}
            download="Daniel Hayes Resume"
            target="_blank"
            rel="noreferrer"
          >
            <PDF className="pdf" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Resume;
